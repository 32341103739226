
.pagination>.disabled>a, .pagination>.disabled>a:focus, .pagination>.disabled>a:hover, .pagination>.disabled>span, .pagination>.disabled>span:focus, .pagination>.disabled>span:hover{
  background-color: #292e33;
}
.pagination>li>a, .pagination>li>span{
  background-color: #292e33;
}

.date-picker-button{
  width: 100px; 
  height: 45px; 
  color: #fff; 
  background: #1d2025; 
  border: #1d2025; 
  border-radius: 4px;
  margin-right: 5px;
}

.react-datepicker-time__input{
  color: #333 !important;
}