.launcher-body {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: white;
}

.launcher-body .logo-container svg {
  height: 150px;
  width: 150px;
}

.launcher-body .logo-container .logo-text h4 {
  font-weight: 700;
  font-size: 45px;
  margin-bottom: 5px;
}

.launcher-body .logo-container .logo-text p {
  margin-bottom: 0;
  font-size: 23px;
}

.launcher-body .logo-container .logo-text {
  margin-top: 10px;
}
